import React, { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import { Formik } from 'formik';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { useDropzone } from 'react-dropzone';
import { css } from 'styled-components';
import Image from 'gatsby-image';
import MailingListPopup from '../components/JoinMailingList/MailingListPopup';
import ReCAPTCHA from "react-google-recaptcha";

// Hooks
import { useBackground } from '../hooks/use-background';

import { appendFormData, getImage } from '~/helpers';
import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';
import { breakpoint } from '../constants/Breakpoint';
import Hero from '~/components/.base/hero';
import { HeadingLarge, BodyTextAlt, LargeStencil } from '~/components/.base/headings';
import { FormControl, FormControlSelect, FormSuccess, FormError } from '~/components/.base/inputs';
import { RedButton } from '~/components/.base/buttons';
import { Grid, Row, Col, FlexBox, BackgroundDiv } from '~/components/.base/containers';
import ArrowDown from '~/images/icons/arrow_normal_down.svg';
import './careers.scss';

const initialErrors = {
  message: null,
  file: null,
  location: null,
  phone: null,
  email: null,
  lastName: null,
  firstName: null,
};

const STATIC_LOCATIONS_MAP = {
  CORPORATE: {
    name: 'Corporate',
    email: 'jlyons@cousinsmainelobster.com'
  }
}

const Careers = ({ data, location }) => {
  const bgImages = useBackground().edges;
  const [file, setFile] = useState(null);
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState('');
  const [isReCaptchaValid, setIsReCaptchaValid] = useState(false);
  const [captchaToken, setCaptchaToken] = useState();
  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    location: '',
    message: '',
  });
  const [hero, setHero] = useState(null);
  const [errors, setErrors] = useState(initialErrors);

  const messageFieldRef = useRef(null);
  const fileFieldRef = useRef(null);
  const locationFieldRef = useRef(null);
  const phoneFieldRef = useRef(null);
  const emailFieldRef = useRef(null);
  const lastNameFieldRef = useRef(null);
  const firstNameFieldRef = useRef(null);

  const handleReCaptchaChange = (value) => {
    setIsReCaptchaValid(!!value);
    setCaptchaToken(value);
  }

  useEffect(() => {
    setHero(
      data.contentfulPage
        ? data.contentfulPage.contentModules.find(m => m.__typename === 'ContentfulHeroSection')
        : null
    );
  }, [data]);
  const submitForm = async (values, { setSubmitting, resetForm }) => {
    setFormError('');
    setSubmitting(true);

    let locationEmail = null;
    const staticLocations = Object.values(STATIC_LOCATIONS_MAP);
    const staticLocation = staticLocations.find(loc => loc.name === values.location);

    if (!!staticLocation) {
      locationEmail = staticLocation.email;
      values.storeLocation = staticLocation.name;
    } else {
      locationEmail = data.allContentfulFoodTruckPage.edges.find(edge => edge.node.title === values.location)?.node
      ?.emailAddress;
      values.storeLocation = values.location;
    }

    values.location = locationEmail;

    const formData = appendFormData(values);
    formData.append('resume', file);
    let verifyCaptchaTokenRes = null;

    try {
      verifyCaptchaTokenRes = await axios.post(`${process.env.GATSBY_SERVER_API_URL}/api/forms/verify-captcha`, {
        token: captchaToken
      });
    } catch (e) {
      setSubmitting(false);
      setFormError(e?.message);
    }

    if (!verifyCaptchaTokenRes?.data?.success) {
      setSubmitting(false);
      setFormError('Please check the recaptcha and try again');

      return;
    }

    axios
      .post(`${process.env.GATSBY_SERVER_API_URL}/api/forms/careers`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
      })
      .then(res => {
        if (window && window.ga) {
          window.ga('send', 'event', 'form', 'submission', 'Careers Form Submission', '1');
        }
        setSubmitting(false);
        setInitialValues(vals => vals);
        setFormSuccess(true);
      })
      .then(() => {
        resetForm({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          location: '',
          message: '',
        });
        setFile(null);
      })
      .catch(err => {
        console.log(err);
        setSubmitting(false);
        setFormError(err.message);
      });
  };
  let uniqueTrucks = [
    ...new Set(data.allContentfulFoodTruckPage.edges.map(edge => edge.node.title).sort()),
  ];
  uniqueTrucks = uniqueTrucks.map(truck => ({
    truck,
    email: data.allContentfulFoodTruckPage.edges.find(edge => edge.node.title === truck).node
      .emailAddress,
  }));
  return (
    <Fragment>
      <Criteo />
      <MailingListPopup />
      <SEO title="Careers" />
      <DeriveHeaderClasses location={location} />
      <div className="careers">
        <LargeStencil
          as="span"
          css={css`
            transform: rotate(90deg);
            top: 6%;
            left: 251px;
            z-index: 4;
            transform-origin: 0 0;
            @media ${breakpoint.maxMd} {
              display: none;
            }
          `}
        >
          Careers
        </LargeStencil>
        <Hero size="full" imageFluid={hero ? hero.heroSlides[0].slideImage.fluid : null} alt="">
          <div className="hero-details">
            <HeadingLarge>Careers</HeadingLarge>
          </div>
        </Hero>

        <div
          css={css`
            position: relative;
            padding: 94px 0 150px;
            z-index: 5;
          `}
        >
          <BackgroundDiv
            z="-1"
            right="0%"
            top="0"
            css={css`
              width: 746px;
              @media ${breakpoint.maxMd} {
                left: 50%;
                transform: translateX(-50%);
              }
            `}
          >
            <Image fluid={getImage(bgImages, 'blurred_smudge@3x')} alt="" />
          </BackgroundDiv>
          <div className="careers-form-cont">
            {formSuccess ? (
              <FormSuccess role="alert">
                <BodyTextAlt>
                  Thank you for your application! We will get back to you shortly.
                </BodyTextAlt>
              </FormSuccess>
            ) : null}
            {formError.length ? (
              <FormError>
                <BodyTextAlt>{formError}</BodyTextAlt>
              </FormError>
            ) : null}
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validateOnChange={false}
              validateOnBlur={false}
              validate={values => {
                const errs = {};

                if (!values.message) {
                  errs.message = 'Required';
                  if (messageFieldRef?.current) messageFieldRef.current.focus();
                }
                if (!file) {
                  errs.file = 'Required';
                  if (fileFieldRef?.current) fileFieldRef.current.focus();
                }
                if (!values.location) {
                  errs.location = 'Required';
                  if (locationFieldRef?.current) locationFieldRef.current.focus();
                }
                if (!values.phone) {
                  errs.phone = 'Required';
                  if (phoneFieldRef?.current) phoneFieldRef.current.focus();
                }
                if (values.email === '') {
                  errs.email = 'Required';
                  if (emailFieldRef?.current) emailFieldRef.current.focus();
                } else if (
                  values.email &&
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errs.email = 'Invalid email address';
                  if (emailFieldRef?.current) emailFieldRef.current.focus();
                }
                if (!values.lastName) {
                  errs.lastName = 'Required';
                  if (lastNameFieldRef?.current) lastNameFieldRef.current.focus();
                }
                if (!values.firstName) {
                  errs.firstName = 'Required';
                  if (firstNameFieldRef?.current) firstNameFieldRef.current.focus();
                }

                setErrors(errs);
                return errs;
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errs,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form
                  className="careers-form"
                  css={css`
                    ${FormControl} input, ${FormControlSelect} select, ${FormControl} textarea, ${FormControl} .file-drop {
                      width: calc(100% - 2rem);
                      @media ${breakpoint.lg} {
                        width: calc(100% - 15px);
                      }
                    }
                    label,
                    .label {
                      margin-bottom: 7px;
                    }
                    ${Row} {
                      @media ${breakpoint.maxMd} {
                        flex-direction: column;
                      }
                    }
                  `}
                >
                  <BodyTextAlt md="0 0 2rem">* indicates a required field</BodyTextAlt>
                  <Grid>
                    <Row>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="firstName" className="label">
                            First Name
                            <span className="red">*</span>
                          </label>
                          <input
                            id="firstName"
                            name="firstName"
                            className={`field${!!errors.firstName ? ' is-invalid' : ''}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                            ref={firstNameFieldRef}
                            aria-describedby="firstNameError"
                            autoComplete="given-name"
                          />
                          <div id="firstNameError" className="error">
                            {errors.firstName}
                          </div>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="lastName" className="label">
                            Last Name
                            <span className="red">*</span>
                          </label>
                          <input
                            id="lastName"
                            name="lastName"
                            className={`field${!!errors.lastName ? ' is-invalid' : ''}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                            ref={lastNameFieldRef}
                            aria-describedby="lastNameError"
                            autoComplete="family-name"
                          />
                          <div id="lastNameError" className="error">
                            {errors.lastName}
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="email" className="label">
                            Email
                            <span className="red">*</span>
                          </label>
                          <input
                            id="email"
                            name="email"
                            className={`field${!!errors.email ? ' is-invalid' : ''}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            ref={emailFieldRef}
                            aria-describedby="emailError"
                            autoComplete="email"
                          />
                          <div id="emailError" className="error">
                            {errors.email}
                          </div>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="phone" className="label">
                            Phone
                            <span className="red">*</span>
                          </label>
                          <input
                            id="phone"
                            name="phone"
                            className={`field${!!errors.phone ? ' is-invalid' : ''}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                            ref={phoneFieldRef}
                            aria-describedby="phoneError"
                            autoComplete="tel"
                          />
                          <div id="phoneError" className="error">
                            {errors.phone}
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControlSelect svgTop="45px" svgRight="35px">
                          <label htmlFor="location" className="label">
                            Location
                            <span className="red">*</span>
                          </label>
                          <select
                            id="location"
                            name="location"
                            className={`location${!!errors.location ? ' is-invalid' : ''}`}
                            placeholder="Select location"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.location}
                            ref={locationFieldRef}
                            aria-describedby="locationError"
                            autoComplete="address-level2"
                          >
                            <option value="">Select Location</option>
                            <option value={STATIC_LOCATIONS_MAP.CORPORATE.name}>Corporate</option>
                            {uniqueTrucks.map(truck => (
                              <option value={truck.truck}>{truck.truck}</option>
                            ))}
                          </select>
                          <ArrowDown
                            style={{
                              pointerEvents: 'none',
                            }}
                          />
                          <div id="locationError" className="error">
                            {errors.location}
                          </div>
                        </FormControlSelect>
                      </Col>

                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="file" className="label">
                            Resume
                            <span className="red">*</span>
                          </label>
                          <div
                            className={`file-drop${file ? '' : ' no-file'}${
                              errors.file ? ' is-invalid' : ''
                            }`}
                            {...getRootProps()}
                          >
                            <input
                              id="file"
                              {...getInputProps()}
                              ref={fileFieldRef}
                              aria-describedby="fileError"
                            />
                            {file ? (
                              <Fragment>
                                <span>{file.name}</span>
                                <button
                                  className="red close"
                                  onClick={e => {
                                    e.stopPropagation();
                                    setFile(null);
                                  }}
                                >
                                  <span className="inner">&times;</span>
                                </button>
                              </Fragment>
                            ) : (
                              <span>Choose a File</span>
                            )}
                          </div>
                          <div id="fileError" className="error">
                            {errors.file}
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="message" className="label">
                            Message
                            <span className="red">*</span>
                          </label>
                          <textarea
                            id="message"
                            name="message"
                            className={`field${!!errors.message ? ' is-invalid' : ''}`}
                            rows="5"
                            placeholder="Enter your message here, we're all ears!"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.message}
                            ref={messageFieldRef}
                            aria-describedby="messageError"
                          />
                          <div id="messageError" className="error">
                            {errors.message}
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    <FlexBox justify="center">
                      <ReCAPTCHA
                        className="recaptcha"
                        sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
                        onChange={handleReCaptchaChange}
                      />
                    </FlexBox>
                    <FlexBox justify="center">
                      <RedButton
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!isReCaptchaValid || (isReCaptchaValid && isSubmitting)}
                        h="50px"
                        pd="0 35px"
                      >
                        {isSubmitting ? (
                          <Fragment>
                            <Loader type="TailSpin" color="#152622" height={16} width={16} />
                            Loading...
                          </Fragment>
                        ) : (
                          'Submit'
                        )}
                      </RedButton>
                    </FlexBox>
                  </Grid>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export const query = graphql`
  query {
    allContentfulFoodTruckPage {
      edges {
        node {
          title
          handle
          isATruck
          emailAddress
        }
      }
    }
    contentfulPage(title: { eq: "Careers" }) {
      title
      contentModules {
        ... on ContentfulHeroSection {
          title
          heroSlides {
            title
            slideImage {
              fluid(maxWidth: 1600) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            mobileImage {
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            buttonText
            whiteHeaderText
            useOverlay
            overlayOpacity
          }
        }
      }
    }
  }
`;
export default Careers;
